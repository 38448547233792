import React, { useState } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import ImageList from "@mui/material/ImageList"
import ImageListItem from "@mui/material/ImageListItem"
import useMediaQuery from "@mui/material/useMediaQuery"
import { styled, ThemeProvider, useTheme } from "@mui/material/styles"
import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"
import Lightbox from "yet-another-react-lightbox"
import Zoom from "yet-another-react-lightbox/plugins/zoom"
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails"
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen"

function Extra({ data }) {

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const [index, setIndex] = useState(-1)

  const ArtCanvas = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    textAlign: "center",
    color: theme.palette.text.secondary
  }))

  const slides = data.allFile.edges.map((item, index) => {
      const imageData = item.node.childImageSharp.gatsbyImageData
      return ({
        src: imageData.images.fallback.src,
        key: index,
        width: imageData.width,
        height: imageData.height

      })
    }
  )

  return (
    <Layout>
      <Box sx={{ mb: 5, ml: 4 }}><h1>Projects</h1></Box>
      <ThemeProvider theme={theme}>
        <ImageList
          variant="masonry" cols={isDesktop ? 3 : 1} gap={30}
        >
          {data.allFile.edges.map((item, index) => (
            <ImageListItem key={`extra-${index}`} onClick={() => {setIndex(index)}}>
              <ArtCanvas>
                <GatsbyImage alt={"Image"} image={item.node.childImageSharp.gatsbyImageData} />
              </ArtCanvas>
            </ImageListItem>
          ))}
        </ImageList>
        <Lightbox
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
          slides={slides}
          plugins={[Zoom, Thumbnails, Fullscreen]}
          zoom={{
            maxZoomPixelRatio: 3,
            scrollToZoom: true
          }}
        />
      </ThemeProvider>
    </Layout>
  )
}

export const indexQuery = graphql`
query Extra {
  allFile(filter: {sourceInstanceName: {eq: "extra"}}) {
    edges {
      node {
        childrenImageSharp {
          gatsbyImageData(width: 300)
        }
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
}



`

export default Extra